import { post } from './index'

const apiPrefix = '/api/auth'

export const authenticate = (params, options) => {
  post(apiPrefix + '/authenticate', params)
    .then((res) => {
      options.callback(res)
    })
    .finally(() => {
      if (options.finally) {
        options.finally()
      }
    })
}

export const enterSystem = (params, options) => {
  post(apiPrefix + '/enter', params)
    .then((res) => {
      options.callback(res)
    })
    .finally(() => {
      if (options.finally) {
        options.finally()
      }
    })
}

export const logout = (options) => {
  post(apiPrefix + '/logout')
    .then((res) => {
      options.callback(res)
    })
    .finally(() => {
      if (options.finally) {
        options.finally()
      }
    })
}

export const validateResetPassword = (params, options) => {
  post(apiPrefix + '/password/reset/validate', params)
    .then((res) => {
      options.callback(res)
    })
    .finally(() => {
      if (options.finally) {
        options.finally()
      }
    })
}

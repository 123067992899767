export default {
  state: {
    isAuthenticate: false,
    functionList: [],
    account: '',
    userName: '',
    roles: [],
  },
  getters: {
    getIsAuthenticate: (state) => {
      return state.isAuthenticate
    },
    getFunctionList: (state) => {
      return state.functionList
    },
    getSidebarList: (state) => {
      let showOnMenuFunction = state.functionList.filter(
        (func) => func.showOnMenu,
      )
      return groupFunctionList(showOnMenuFunction)
    },
    getAccount: (state) => {
      return state.account
    },
    getUserName: (state) => {
      return state.userName
    },
    getRoles: (state) => {
      return state.roles
    },
  },
  mutations: {
    signin: (state, payload) => {
      state.isAuthenticate = payload.isAuthenticated
      state.functionList = payload.functionList
      state.account = payload.account
      state.userName = payload.userName
      state.roles = payload.roles
    },
    signout: (state) => {
      state.isAuthenticate = false
      state.functionList = []
      state.account = ''
      state.userName = ''
      state.roles = []
    },
  },
  actions: {},
}

const groupFunctionList = (functionList) => {
  let result = []
  for (let func of functionList) {
    if (!func.parent) {
      func.children = getChildren(functionList, func.id)
      result.push(func)
    }
  }
  return result
}

const getChildren = (functionList, parentId) => {
  if (!functionList || functionList.length === 0 || !parentId) {
    return null
  }
  let result = []
  for (let func of functionList) {
    if (parentId === func.parent) {
      func.children = getChildren(functionList, func.id)
      result.push(func)
    }
  }
  return result
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import i18n from './i18n'
import './plugins/snotify'
import './plugins/swiper'
import './plugins/vue-ics'
import './plugins/device-detector'
import './service/consts/systemConst'
import '@/components/global'

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

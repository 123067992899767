import Vue from 'vue'
import Vuex from 'vuex'
import User from '@/store/moudules/user.js'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: User,
  },
  plugins: [createPersistedState()],
})

import Header from '@/components/header/Header.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      header: Header,
      mainView: () => import('@/views/home/Home.vue'),
    },
    meta: {},
  },
  {
    path: '/signup',
    name: 'signup',
    components: {
      header: Header,
      mainView: () => import('@/views/signUp/SignUp.vue'),
    },
    meta: {},
  },
  {
    path: '/signin',
    name: 'signin',
    components: {
      header: Header,
      mainView: () => import('@/views/signIn/SignIn.vue'),
    },
    meta: {},
  },
  /** 儀錶板 */
  {
    path: '/dashboard',
    name: 'dashboard',
    components: {
      header: Header,
      mainView: () => import('@/views/dashboard/Dashboard.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/study/new',
    name: 'newStudy',
    components: {
      header: Header,
      mainView: () => import('@/views/studyOperation/StudyOperation.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/password/forgot',
    name: 'forgotPassword',
    components: {
      header: Header,
      mainView: () => import('@/views/forgotPassword/ForgotPassword.vue'),
    },
  },
  {
    path: '/password/reset',
    name: 'resetPassword',
    components: {
      header: Header,
      mainView: () => import('@/views/resetPassword/ResetPassword.vue'),
    },
  },
  {
    path: '/personal/profile',
    name: 'personalProfile',
    components: {
      header: Header,
      mainView: () => import('@/views/personalProfile/PersonalProfile.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/study/my',
    name: 'myStudy',
    components: {
      header: Header,
      mainView: () => import('@/views/myStudy/MyStudy.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/study/query',
    name: 'queryStudy',
    components: {
      header: Header,
      mainView: () => import('@/views/studyQuery/StudyQuery.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/study/participate',
    name: 'participateStudy',
    components: {
      header: Header,
      mainView: () => import('@/views/participateStudy/ParticipateStudy.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/study/:studyNo/participant/management',
    name: 'participantManagement',
    components: {
      header: Header,
      mainView: () =>
        import('@/views/participantManagement/ParticipantManagement.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/match/:matchNo/info',
    name: 'studyMatchInfo',
    components: {
      header: Header,
      mainView: () => import('@/views/studyMatchInfo/StudyMatchInfo.vue'),
    },
    meta: {
      private: true,
    },
  },
  /** 研究案媒合 */
  {
    path: '/study/:studyNo/match',
    name: 'studyMatch',
    components: {
      header: Header,
      mainView: () => import('@/views/studyMatch/StudyMatch.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/study/match/request',
    name: 'personalStudyMatchRequest',
    components: {
      header: Header,
      mainView: () => import('@/views/studyMatchRequest/StudyMatchRequest.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/study/:studyNo/match/request',
    name: 'studyMatchRequest',
    components: {
      header: Header,
      mainView: () => import('@/views/studyMatchRequest/StudyMatchRequest.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/match/:matchNo/auth',
    name: 'studyMatchAuth',
    components: {
      header: Header,
      mainView: () => import('@/views/studyMatchAuth/StudyMatchAuth.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/study/:studyNo',
    name: 'researcherStudyInfo',
    components: {
      header: Header,
      mainView: () => import('@/views/studyInfo/StudyInfo.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/study/:studyNo/edit',
    name: 'researcherStudyInfoEdit',
    components: {
      header: Header,
      mainView: () => import('@/views/studyOperation/StudyOperation.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/study/match/my',
    name: 'myStudyMatch',
    components: {
      header: Header,
      mainView: () => import('@/views/myStudyMatch/MyStudyMatch.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/role/management',
    name: 'roleManagement',
    components: {
      header: Header,
      mainView: () => import('@/views/roleManagement/RoleManagement.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/mail/management',
    name: 'mailManagement',
    components: {
      header: Header,
      mainView: () => import('@/views/mailManagement/MailManagement.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/account/management',
    name: 'accountManagement',
    components: {
      header: Header,
      mainView: () => import('@/views/accountManagement/AccountManagement.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/account/audit',
    name: 'accountAudit',
    components: {
      header: Header,
      mainView: () => import('@/views/accountAudit/AccountAudit.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/attribute/management',
    name: 'attributeManagement',
    components: {
      header: Header,
      mainView: () =>
        import('@/views/attributeManagement/AttributeManagement.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/participant/blacklist/management',
    name: 'ParticipantBlacklistManagement',
    components: {
      header: Header,
      mainView: () =>
        import(
          '@/views/participantBlacklistManagement/ParticipantBlacklistManagement.vue'
        ),
    },
    meta: {
      private: true,
    },
  },
  {
    path: '/irb/management',
    name: 'IrbManagement',
    components: {
      header: Header,
      mainView: () => import('@/views/irbManagement/IrbManagement.vue'),
    },
    meta: {
      private: true,
    },
  },
  {
    // 請勿更動此順序
    path: '*',
    name: '404',
    components: {
      mainView: () => import('@/views/404/404.vue'),
    },
  },
]

export default routes

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#34b2d8',
        unremarkable: '#aaaaaa',
        background: '#F5F5F5',
      },
    },
  },
})

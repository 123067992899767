<template>
  <v-app :style="bg">
    <router-view name="header"></router-view>
    <v-main>
      <router-view name="mainView" />
    </v-main>
    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({}),
  computed: {
    bg() {
      if (this.$route.meta.private) {
        return 'background-color: #F5F5F5'
      } else {
        return ''
      }
    },
  },
}
</script>

import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import { logout } from '@/service/http/authenticateService'
import i18n from '@/i18n'

export const ApiStatus = {
  Success: '00',
  Fail: '01',
  SystemError: '99',
}

const instance = axios.create({
  withCredentials: true,
})

instance.interceptors.request.use(
  (config) => {
    config.headers = {
      // 如果沒有cors的問題則可以都不加(已設定proxy)
      // 'Access-Control-Allow-Origin': 'http://localhost:8080',
      // 'Access-Control-Allow-Methods': '*',
      // 'Access-Control-Max-Age': '86400',
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      Vue.prototype.$snotify.warning(
        i18n.t('please_login_again'),
        i18n.t('login_expired'),
      )
      logout({})
      store.commit({
        type: 'signout',
      })
      router.push({ path: '/signin' })
    } else if (
      error.response.status === 500 ||
      error.response.data.returnCode === ApiStatus.SystemError
    ) {
      Vue.prototype.$snotify.error(
        i18n.t('please_contact_admin'),
        i18n.t('backend_error.system_0001'),
      )
    }
    return Promise.reject(error)
  },
)

export function fetch(url, params = {}, config = {}) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
        ...config,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function post(url, data = {}, config = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data, config).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        reject(err)
      },
    )
  })
}

export function remove(url, data = {}, config = {}) {
  return new Promise((resolve, reject) => {
    instance.delete(url, data, config).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        reject(err)
      },
    )
  })
}

export function put(url, data = {}, config = {}) {
  return new Promise((resolve, reject) => {
    instance.put(url, data, config).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        reject(err)
      },
    )
  })
}

import Vue from 'vue'
import { ApiStatus } from '@/service/http'
const systemConsts = {
  BasicAttribute: {
    SEX: { id: 1, name: 'sex' },
    GENDER: { id: 2, name: 'gender' },
    HANDEDNESS: { id: 3, name: 'handedness' },
    HAS_METAL_IMPLANT: { id: 4, name: 'has_metal_implant' },
    RELIGION: { id: 5, name: 'religion' },
    EDUCATIONAL_LEVEL: { id: 6, name: 'educational_level' },
    ANNUAL_INCOME: { id: 7, name: 'annual_income.name' },
    AGE: { id: 8, name: 'age' },
    CURRENT_RESIDENCE: { id: 9, name: 'current_residence' },
    RATING: { id: 10, name: 'score' },
    BM_DATA: { id: 11, name: 'brain_mri_file' },
  },
  ApiStatus: { ...ApiStatus },
  Role: {
    ADMIN: 'ROLE_ADMIN',
    OPERATOR: 'ROLE_OPERATOR',
    RESEARCHER: 'ROLE_RESEARCHER',
    PARTICIPANT: 'ROLE_PARTICIPANT',
  },
  StudyStatus: {
    OPEN: 'O',
    PENDING: 'P',
    CLOSE: 'C',
    CANCEL: 'X',
  },
  MatchStatus: {
    OPEN: 'O',
    APPROVE: 'A',
    REJECT: 'R',
  },
  AuditStatus: {
    OPEN: 'O',
    COMPLETE: 'C',
    REJECT: 'R',
  },
}

systemConsts.install = function () {
  Object.defineProperty(Vue.prototype, '$systemConsts', {
    get() {
      return systemConsts
    },
  })
}

Vue.use(systemConsts)

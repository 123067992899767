export default {
  name: 'DatePicker',

  props: [
    'label',
    'dense',
    'hideDetails',
    'messages',
    'errorMessages',
    'filled',
    'value', //指定預設的日期,預設為空
  ],
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    date: {
      get() {
        return this.value
      },
      set(value) {
        this.$refs.menu.save(value)
        this.currentValue(value)
        this.$emit('input', value)
      },
    },
  },
  methods: {
    currentValue(arg) {
      this.$emit('currentValue', arg || this.date)
    },
    resetData() {
      this.date = this.defaultDate || ''
      this.menu = false
    },
  },
}

import store from '@/store'

const excludePath = ['/', '/signup', '/signin']

export const security = (to, from, next) => {
  if (excludePath.indexOf(to.path) !== -1) {
    next()
    return
  }
  if (to.meta.private) {
    if (store.getters.getIsAuthenticate) {
      const functionList = store.getters.getFunctionList
      for (let functionItem of functionList) {
        for (let matchedRoute of to.matched) {
          if (matchedRoute.path === functionItem.link) {
            next()
            return
          }
        }
      }
    } else if (to.name !== '404') {
      next({ name: 'signin' })
      return
    }
    next({ name: '404', params: { 0: to.path } })
  } else {
    next()
  }
}

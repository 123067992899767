export default {
  name: 'TimePicker',
  props: [
    'labelText', //顯示於placeholder的字串,預設為空字串
    'value', //指定預設的時間,預設為空
  ],
  data() {
    return {
      menu: false,
      format: this.formatType || 'ampm',
      label: this.labelText || '',
    }
  },
  computed: {
    time: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    selectTime(time) {
      this.$refs.menu.save(time)
      this.time = time
    },
    changeHour(hour) {
      this.$emit('changeHour', hour)
    },
    resetData() {
      this.time = this.defaultTime || null
      this.menu = false
      this.format = this.formatType || 'ampm'
      this.label = this.labelText || ''
    },
  },
}

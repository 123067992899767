import { mapGetters } from 'vuex'
import { logout } from '@/service/http/authenticateService'
import SidebarItem from '@/components/sidebarItem/SidebarItem.vue'

export default {
  name: 'Header',
  components: {
    SidebarItem,
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    ...mapGetters({
      isAuthenticate: 'getIsAuthenticate',
      getSidebarList: 'getSidebarList',
    }),
    isPrivate() {
      return this.$route.meta.private
    },
    isSigninPage() {
      return this.$route.path === '/signin'
    },
  },
  methods: {
    toHome() {
      if (this.$route.path !== '/') {
        this.drawer = false
        this.$router.push({ path: '/' })
      }
    },
    logout() {
      logout({
        callback: this.logoutCallback,
      })
    },
    logoutCallback() {
      this.$store.commit({
        type: 'signout',
      })
      this.$router.push({ path: '/' })
    },
  },
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import routesConfig from './routes'
import { security } from './security'

Vue.use(VueRouter)

const routes = [...routesConfig]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  security(to, from, next)
})

export default router

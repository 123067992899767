import Vue from 'vue'
import CustomBaseButton from '@/components/button/base/CustomBaseButton.vue'
import CustomBaseCard from '@/components/card/base/CustomBaseCard.vue'
import DatePicker from '@/components/datePicker/DatePicker.vue'
import TimePicker from '@/components/timePicker/TimePicker.vue'
import Password from '@/components/password/Password.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

Vue.component('CBtn', CustomBaseButton)
Vue.component('CCard', CustomBaseCard)
Vue.component('CDatePicker', DatePicker)
Vue.component('CTimePicker', TimePicker)
Vue.component('Password', Password)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
